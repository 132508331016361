/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { 
  RiAwardLine, 
  RiBriefcase3Line, 
  RiGlobalLine, 
  RiTeamLine,
  RiBarChartBoxLine, 
  RiRocketLine,
  RiSettings5Line,
  RiUserStarLine,
  RiBuilding4Line,
  RiArrowRightSLine,
  RiCheckboxCircleLine
} from "react-icons/ri"

export const pageQuery = graphql`
  query AboutQuery($id: String!){
		markdownRemark(id: { eq: $id }) {
      id
			html
			excerpt(pruneLength: 140)
      frontmatter {
        title
      }
    }
  }
`

const AboutPage = ({ data }) => {
	const { markdownRemark } = data
  const { frontmatter, html, excerpt } = markdownRemark

	return (
		<Layout className="page">
			<SEO
				title="PRS Consultancy - Enterprise HR Transformation and Implementation"
				description="The premier HR consultancy for enterprise transformation. With 80+ implementations and 500+ global clients, we deliver strategic HR solutions with measurable business outcomes."
				keywords={[
					"enterprise HR consulting", 
					"HR transformation services", 
					"global HR strategy", 
					"HR technology implementation", 
					"HR process optimization",
					"workforce analytics consulting",
					"HR operating model design",
					"HR service delivery transformation",
					"strategic HR advisory",
          "PRS Consultancy",
          "HR solution provider"
				]}
			/>
      
      {/* Hero Section */}
      <div sx={{
        background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
        pt: [3, 4],
        pb: [4, 5],
        textAlign: 'center'
      }}>
        <div sx={{
          maxWidth: '1100px',
          mx: 'auto',
          px: [3, 4]
        }}>
          <h1 sx={{
            fontSize: ['2rem', '2.5rem', '3rem'],
            fontWeight: 800,
            mb: 2,
            lineHeight: 1.2,
            color: 'primary'
          }}>
            Enterprise HR Transformation & Strategic Consulting
          </h1>
          
          <p sx={{
            fontSize: ['1.1rem', '1.25rem'],
            maxWidth: '800px',
            mx: 'auto',
            color: 'text',
            opacity: 0.9,
            lineHeight: 1.5,
            mb: 4
          }}>
            The industry leader in enterprise HR transformation. We drive measurable business outcomes through innovative HR strategies and technologies.
          </p>
          
          <div sx={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: 4,
            mb: 2
          }}>
            <div sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              bg: 'white',
              boxShadow: '0 2px 6px rgba(0,0,0,0.05)',
              px: 3,
              py: 2,
              borderRadius: '6px'
            }}>
              <RiGlobalLine sx={{ fontSize: '1.75rem', color: 'primary' }} />
              <div sx={{ textAlign: 'left' }}>
                <span sx={{ display: 'block', fontSize: '0.9rem', color: 'text', opacity: 0.8 }}>Implementation Expertise</span>
                <span sx={{ fontSize: '1.25rem', fontWeight: 700, color: 'text' }}>80+ Enterprise Projects</span>
              </div>
            </div>
            
            <div sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              bg: 'white',
              boxShadow: '0 2px 6px rgba(0,0,0,0.05)',
              px: 3,
              py: 2,
              borderRadius: '6px'
            }}>
              <RiBriefcase3Line sx={{ fontSize: '1.75rem', color: 'primary' }} />
              <div sx={{ textAlign: 'left' }}>
                <span sx={{ display: 'block', fontSize: '0.9rem', color: 'text', opacity: 0.8 }}>Global Reach</span>
                <span sx={{ fontSize: '1.25rem', fontWeight: 700, color: 'text' }}>500+ Clients</span>
              </div>
            </div>
            
            <div sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              bg: 'white',
              boxShadow: '0 2px 6px rgba(0,0,0,0.05)',
              px: 3,
              py: 2,
              borderRadius: '6px'
            }}>
              <RiAwardLine sx={{ fontSize: '1.75rem', color: 'primary' }} />
              <div sx={{ textAlign: 'left' }}>
                <span sx={{ display: 'block', fontSize: '0.9rem', color: 'text', opacity: 0.8 }}>Recognition</span>
                <span sx={{ fontSize: '1.25rem', fontWeight: 700, color: 'text' }}>Industry-Leading Expertise</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Services Section */}
      <div sx={{
        py: [3, 4],
        bg: 'white'
      }}>
        <div sx={{
          maxWidth: '1100px',
          mx: 'auto',
          px: [3, 4]
        }}>
          <h2 sx={{
            fontSize: ['1.75rem', '2rem'],
            fontWeight: 700,
            textAlign: 'center',
            mb: 3,
            color: 'primary'
          }}>
            Global Consulting Excellence
          </h2>
          
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr 1fr'],
            gap: 4,
            mb: 3
          }}>
            <div sx={{
              bg: 'backgroundMuted',
              p: 4,
              borderRadius: '8px',
              transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
              '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 10px 30px rgba(0,0,0,0.1)'
              }
            }}>
              <RiSettings5Line sx={{ fontSize: '2rem', color: 'primary', mb: 2 }} />
              <h3 sx={{ fontSize: '1.25rem', fontWeight: 600, mb: 2, color: 'primary' }}>
                Strategic HR Transformation
              </h3>
              <p sx={{ fontSize: '0.95rem', color: 'text', lineHeight: 1.6 }}>
                Enterprise-wide HR operating model redesign and implementation
              </p>
            </div>
            
            <div sx={{
              bg: 'backgroundMuted',
              p: 4,
              borderRadius: '8px',
              transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
              '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 10px 30px rgba(0,0,0,0.1)'
              }
            }}>
              <RiBuilding4Line sx={{ fontSize: '2rem', color: 'primary', mb: 2 }} />
              <h3 sx={{ fontSize: '1.25rem', fontWeight: 600, mb: 2, color: 'primary' }}>
                HR Technology Ecosystems
              </h3>
              <p sx={{ fontSize: '0.95rem', color: 'text', lineHeight: 1.6 }}>
                Advanced HRIS, HCM, and talent management platform architecture
              </p>
            </div>
            
            <div sx={{
              bg: 'backgroundMuted',
              p: 4,
              borderRadius: '8px',
              transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
              '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 10px 30px rgba(0,0,0,0.1)'
              }
            }}>
              <RiBarChartBoxLine sx={{ fontSize: '2rem', color: 'primary', mb: 2 }} />
              <h3 sx={{ fontSize: '1.25rem', fontWeight: 600, mb: 2, color: 'primary' }}>
                Workforce Analytics
              </h3>
              <p sx={{ fontSize: '0.95rem', color: 'text', lineHeight: 1.6 }}>
                AI-powered insights for strategic workforce planning and talent optimization
              </p>
            </div>
          </div>
          
          <div sx={{
            textAlign: 'center',
            mt: 3
          }}>
            <Link to="/contact" sx={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: 2,
              bg: 'primary',
              color: 'white',
              px: 4,
              py: 2,
              fontSize: '1rem',
              fontWeight: 600,
              borderRadius: '30px',
              textDecoration: 'none',
              boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
              transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: '0 6px 15px rgba(0,0,0,0.15)'
              }
            }}>
              Explore All Services <RiArrowRightSLine />
            </Link>
          </div>
        </div>
      </div>
			
      {/* Success Stories */}
      <div sx={{
        py: [3, 4],
        bg: 'backgroundMuted'
      }}>
        <div sx={{
          maxWidth: '1100px',
          mx: 'auto',
          px: [3, 4]
        }}>
          <h2 sx={{
            fontSize: ['1.75rem', '2rem'],
            fontWeight: 700,
            textAlign: 'center',
            mb: 3,
            color: 'primary'
          }}>
            Enterprise Client Success
          </h2>
          
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
            gap: 4
          }}>
            <div sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 4px 12px rgba(0,0,0,0.05)'
            }}>
              <div sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: 3,
                mb: 0
              }}>
                <RiCheckboxCircleLine sx={{ color: 'primary', fontSize: '1.5rem', flexShrink: 0, mt: 1 }} />
                <p sx={{ fontSize: '1rem', lineHeight: 1.6, m: 0 }}>
                  <strong>Global Process Standardization:</strong> Implemented unified HR processes across 42 countries for a Fortune 100 manufacturer
                </p>
              </div>
            </div>
            
            <div sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 4px 12px rgba(0,0,0,0.05)'
            }}>
              <div sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: 3,
                mb: 0
              }}>
                <RiCheckboxCircleLine sx={{ color: 'primary', fontSize: '1.5rem', flexShrink: 0, mt: 1 }} />
                <p sx={{ fontSize: '1rem', lineHeight: 1.6, m: 0 }}>
                  <strong>Enterprise Talent System:</strong> Designed and deployed integrated talent management for a 75,000-employee financial services organization
                </p>
              </div>
            </div>
            
            <div sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 4px 12px rgba(0,0,0,0.05)'
            }}>
              <div sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: 3,
                mb: 0
              }}>
                <RiCheckboxCircleLine sx={{ color: 'primary', fontSize: '1.5rem', flexShrink: 0, mt: 1 }} />
                <p sx={{ fontSize: '1rem', lineHeight: 1.6, m: 0 }}>
                  <strong>Service Delivery Transformation:</strong> Transformed HR service models for Global 500 organizations, reducing costs by 37%
                </p>
              </div>
            </div>
            
            <div sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 4px 12px rgba(0,0,0,0.05)'
            }}>
              <div sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: 3,
                mb: 0
              }}>
                <RiCheckboxCircleLine sx={{ color: 'primary', fontSize: '1.5rem', flexShrink: 0, mt: 1 }} />
                <p sx={{ fontSize: '1rem', lineHeight: 1.6, m: 0 }}>
                  <strong>Advanced Analytics:</strong> Developed AI-powered workforce analytics capabilities for strategic decision-making across enterprise clients
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Approach Section */}
      <div sx={{
        py: [3, 4],
        bg: 'white'
      }}>
        <div sx={{
          maxWidth: '1100px',
          mx: 'auto',
          px: [3, 4]
        }}>
          <h2 sx={{
            fontSize: ['1.75rem', '2rem'],
            fontWeight: 700,
            textAlign: 'center',
            mb: 2,
            color: 'primary'
          }}>
            Our Proprietary Approach
          </h2>
          
          <p sx={{
            fontSize: '1.1rem',
            textAlign: 'center',
            maxWidth: '700px',
            mx: 'auto',
            mb: 3
          }}>
            Our transformation methodology combines strategic vision with flawless execution
          </p>
          
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr 1fr'],
            gap: [3, 4],
            mb: 3
          }}>
            {[
              {
                id: "strategic-assessment",
                icon: <RiBarChartBoxLine />,
                title: "Strategic Assessment",
                description: "Comprehensive evaluation of current HR capabilities against industry benchmarks"
              },
              {
                id: "future-state-design",
                icon: <RiRocketLine />,
                title: "Future-State Design",
                description: "Collaborative design of optimal HR processes, systems, and service delivery models"
              },
              {
                id: "technology-architecture",
                icon: <RiSettings5Line />,
                title: "Technology Architecture",
                description: "Enterprise system selection, integration architecture, and implementation roadmaps"
              },
              {
                id: "implementation-excellence",
                icon: <RiGlobalLine />,
                title: "Implementation Excellence",
                description: "Global deployment capabilities with local expertise and accelerators"
              },
              {
                id: "change-enablement",
                icon: <RiTeamLine />,
                title: "Change Enablement",
                description: "Organizational readiness, training, and adoption strategies for sustainable transformation"
              },
              {
                id: "continuous-optimization",
                icon: <RiUserStarLine />,
                title: "Continuous Optimization",
                description: "Ongoing performance measurement and iterative improvement frameworks"
              }
            ].map((step, index) => (
              <div key={step.id} sx={{
                position: 'relative',
                bg: 'backgroundMuted',
                p: 3,
                borderRadius: '8px',
                transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0 10px 30px rgba(0,0,0,0.1)'
                }
              }}>
                <div sx={{
                  fontSize: '2rem',
                  color: 'primary',
                  textAlign: 'center',
                  mt: 2,
                  mb: 2
                }}>
                  {step.icon}
                </div>
                <h3 sx={{
                  fontSize: '1.1rem',
                  fontWeight: 600,
                  textAlign: 'center',
                  mb: 1,
                  color: 'primary'
                }}>
                  {step.title}
                </h3>
                <p sx={{
                  fontSize: '0.9rem',
                  textAlign: 'center',
                  color: 'text',
                  lineHeight: 1.5,
                  mb: 0
                }}>
                  {step.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      
      {/* Call to Action */}
      <div sx={{
        py: [3, 4],
        bg: 'primary',
        color: 'white',
        textAlign: 'center'
      }}>
        <div sx={{
          maxWidth: '800px',
          mx: 'auto',
          px: [3, 4]
        }}>
          <h2 sx={{
            fontSize: ['1.75rem', '2rem'],
            fontWeight: 700,
            mb: 2
          }}>
            Ready to Transform Your HR Function?
          </h2>
          
          <p sx={{
            fontSize: '1.1rem',
            opacity: 0.9,
            mb: 3,
            lineHeight: 1.6
          }}>
            Partner with the recognized leader in enterprise HR transformation. Our team of seasoned consultants is ready to help you design and implement world-class HR processes and technologies.
          </p>
          
          <Link to="/contact" sx={{
            display: 'inline-flex',
            alignItems: 'center',
            gap: 2,
            bg: 'white',
            color: 'primary',
            px: 4,
            py: 3,
            fontSize: '1.1rem',
            fontWeight: 700,
            borderRadius: '30px',
            textDecoration: 'none',
            boxShadow: '0 4px 15px rgba(0,0,0,0.15)',
            transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
            '&:hover': {
              transform: 'translateY(-3px)',
              boxShadow: '0 6px 20px rgba(0,0,0,0.2)'
            }
          }}>
            Schedule an Executive Briefing <RiArrowRightSLine />
          </Link>
        </div>
      </div>
      
      {/* Other content can be rendered from markdown if needed */}
      <div sx={{
        display: 'none'
      }}>
        <div dangerouslySetInnerHTML={{ __html: html }} />
			</div>
		</Layout>
	)
}

export default AboutPage